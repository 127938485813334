"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REPORT_OPTIONS = void 0;
const REPORT_OPTIONS = [{
  value: 4,
  label: "Commissioning Report",
  funcName: "commissioningReport",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 9,
  label: "Offline Points Report",
  funcName: "jobSendSiteOfflinePoints",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 10,
  label: "ZT Setpoint Outliers Report",
  funcName: "ztSPOutliersReport",
  components: ["SiteSelect", "EmailSelect", "Date"]
}, {
  value: 11,
  label: "ZT Sensor Health Report",
  funcName: "ztSensorHealthReport",
  components: ["SiteSelect", "EmailSelect", "DateRange"]
}, {
  value: 14,
  label: "Filter DP Report",
  funcName: "filterDPReport",
  components: ["SiteSelect", "EmailSelect", "DateRange"]
}, {
  value: 16,
  label: "Spark Validation Report",
  funcName: "sparkValidationReport",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 17,
  label: "Meter Data Quality Report (Site - 3 days)",
  funcName: "meterDataQualityReportSite",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 18,
  label: "Meter Data Quality Report (Project - 3 days)",
  funcName: "meterDataQualityReportProject",
  components: ["ProjectSelect", "EmailSelect"]
}, {
  value: 20,
  label: "Meters with No or Low Consumption Report",
  funcName: "meterLowOrNoConsumptionReport",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 21,
  label: "Equip Running to Set Point Report",
  funcName: "equipRunningToSetPointReport",
  components: ["SiteSelect", "EmailSelect"]
}, {
  value: 22,
  label: "SP Changed Report",
  funcName: "spChangedReport",
  components: ["SiteSelect", "EmailSelect", "Date"]
}, {
  value: 23,
  label: "VAV Maintenance Report",
  funcName: "vavMaintenanceReport",
  components: ["SiteSelect", "EmailSelect", "DateRange"]
}, {
  value: 24,
  label: "Site Data Health Check",
  funcName: "siteDataHealthReport",
  components: ["SiteSelect", "EmailSelect", "DateRangeYesterday"]
}];
exports.REPORT_OPTIONS = REPORT_OPTIONS;